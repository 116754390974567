<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit" >
			<template #title>
				<modalHeader :title="isEdit ? '编辑人员' : '新增人员'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
					<!-- <a-form-item label="账户" name="username" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.username" placeholder="请输入姓名"></a-input>
					</a-form-item> -->
					
					<a-form-item label="姓名" name="nickname" :rules="[{ required: true, message: '请输入姓名!' }]">
						<a-input v-model:value="modelRef.nickname" placeholder="请输入姓名"></a-input>
					</a-form-item>
					<!-- { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号'} -->
					<a-form-item label="手机号" name="phone">
						<a-input v-model:value="modelRef.phone" placeholder="请输入手机号"></a-input>
					</a-form-item>
					
					<a-form-item label="单位">
						<a-select @select="getAllDepartmentList" v-model:value="modelRef.unitId"  placeholder="请选择所在单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="部门">
						<a-select v-model:value="modelRef.departmentId"  placeholder="请选择所在部门">
							<a-select-option v-for="(item, index) in depList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="职位">
						<a-input v-model:value="modelRef.position" placeholder="请输入职位"></a-input>
					</a-form-item>
					<!-- <a-form-item label="职位">
						<a-select v-model:value="modelRef.position"  allow-clear placeholder="请选择职位">
							<a-select-option :value="1">科长</a-select-option>
							<a-select-option :value="2">主任</a-select-option>
							<a-select-option :value="3">股长</a-select-option>
							<a-select-option :value="4">局长</a-select-option>
							<a-select-option :value="5">副局长</a-select-option>
						</a-select>
					</a-form-item> -->
<!-- 					
					<div style="padding-left: 70px;margin-bottom: 20px;">
						<a-checkbox v-model:checked="modelRef.isOpen" :disabled="isEdit && modelRef.isOpen">快速开通账号</a-checkbox>
					</div>
					
					<a-form-item v-if="modelRef.isOpen" label="请选择角色" name="roleIds" :rules="[{required: true}]">
						<a-select mode="tags" v-model:value="modelRef.roleIds" placeholder="请选择角色">
							<a-select-option v-for="(item, index) in AvailableList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item> -->
					<!-- <a-form-item label="密码" v-if="isOpen">
						<a-input type="password" v-model:value="modelRef.password" placeholder="请输入密码"></a-input>
					</a-form-item> -->
					<!-- <a-form-item label="密码" v-if="modelRef.isOpen" name="password" :rules="(!isEdit && modelRef.isOpen)?[{ required: true, messages: '必填项不允许为空' }]:[]">
						<a-input type="password" v-model:value="modelRef.password" placeholder="请输入密码"></a-input>
					</a-form-item> -->
					<!-- <a-form-item label="密码" v-if="isEdit && modelRef.isOpen" name="password">
						<a-input type="password" v-model:value="modelRef.password" placeholder="请输入密码"></a-input>
					</a-form-item> -->
					<!-- <a-form-item label="原密码" v-if="isEdit && modelRef.isOpen" name="oldPassword" :rules="[{ required: true, messages: '必填项不允许为空' }]">
						<a-input type="password" v-model:value="modelRef.oldPassword" placeholder="请输入密码"></a-input>
					</a-form-item> -->
					<!-- <a-form-item label="新密码" v-if="isEdit && modelRef.isOpen" name="newPassword" :rules="[{ required: true, messages: '必填项不允许为空' }]">
						<a-input type="password" v-model:value="modelRef.newPassword" placeholder="请输入新密码"></a-input>
					</a-form-item -->
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { getAllDepartmentList } from '@/service/modules/global.js';
	import { userUpdate, userAdd, getMyAvailableList } from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	// import { number } from 'echarts';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				visible: false,
				isEdit: false,
				AvailableList: [],// 可分配角色
				// isOpen: false,
				modelRef: {
					
				},
				depList: [] // 选中单位是的部门列表
			}
		},
		created() {
			// this.getMyAvailableList()
		},
		methods: {
			async getMyAvailableList() {
				let ret = await getMyAvailableList({});
				if (ret.code === 200) {
					this.AvailableList = ret.data
				}
			},
			async getAllDepartmentList(value) {
				this.modelRef.departmentId = null
				let ret = await getAllDepartmentList({
					unitId: value
				});
				if (ret.code === 200) {
					this.depList = ret.data
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.modelRef)
					if(postData.roleIds) postData.roleIds = postData.roleIds.join(',')
					// if(this.on)
					postData.isOpen = postData.isOpen ? 1 : 0
					postData = JSON.parse(JSON.stringify(postData));
					// console.log("sumbit",postData,this.isOpen,)
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await userUpdate(postData);
						} else {
							ret = await userAdd(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						id: e.id,
						nickname: e.nickname,
						unitId: e.unitId ? e.unitId : undefined,
						phone: e.phone,
						departmentId: e.depId ? e.depId : undefined,
						isDisabled: e.isDisabled,
						position: e.position ? e.position : undefined,
						isOpen: e.isOpen ? true : false,
						roleIds: e.roleIds
					}
					this.getAllDepartmentList(e.unitId).then(()=>{
						this.modelRef.departmentId = e.depId ? e.depId : undefined
					})
					this.isEdit = true;
				} else {
					this.modelRef = {
						isOpen: false,
						unitId: this.$store.state.system.userInfo.unitId
					};
					this.getAllDepartmentList(this.modelRef.unitId)
					this.isEdit = false;
				}
				// this.getMyAvailableList()
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.visible = false;
				this.isOpen = false;
				this.modelRef = {
					isOpen: false
				};
				this.depList = []
			}
		}
	}
</script>

<style>
</style>